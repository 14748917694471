@import "../../public/static/fonts/Montserrat-Alt1";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700;800&display=swap');
:root{
  font-size: 15px;
  --main-color:#000000;
  --secondary-color:#260B31;
  --tertiary-color:#53136D;
  --quaternary-color:#7D299F;
  --quinary-color:#A73DD2;
  --white:#FFFFFF;
  --red: #FF0000;
  --orange: #fb5607;
  --yellow: #FFE600;
  --green: #43A443;
  --blue: #5E6BAF;
  --black: #000000;
  --gray:#6A6A6A;
  --disabled-color:#9B9B9B;
  --nav-default-width:70px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none!important;
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0px 1000px #ffff inset;
}
input,
textarea,
select {
  color:#000000;
}
*{
  color:#FFFFFF;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

main{
  display: grid;
  width: 100% ;
  max-width: 1080px;
  scrollbar-color: var(--secondary-color) transparent;
  scrollbar-width: thin;
  place-items: center; 
  padding-top: 120px;
}
*{
  scrollbar-color: var(--secondary-color) transparent;
  scrollbar-width: thin;
}
main::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

main::-webkit-scrollbar-track-piece  {
  background-color: transparent;
}

main::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: var(--secondary-color);
}
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

::-webkit-scrollbar-track-piece  {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: var(--secondary-color);
}
h1{
  font-size:2rem;
}

*{
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

body{
  width: 100%;
  height: 100vh;
  display: flex;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: center;
  background-size: cover;
  flex-direction: column;
  place-items: center;
  background-color: var(--secondary-color);
}
body.navigating::after{
  content: "";
  position: absolute;
  top: 70px;
  width: 100%;
  height: 3px;
  background-color: var(--quaternary-color);
  z-index: 1000000000000;
}
body.navigating::after{
  animation: navigating 1s ease-in-out infinite;
}
@keyframes navigating{
  0%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(100%);
  }
}
.mark-dev{
  position: absolute;
  transform: scale(2.5);
  top:25px;
  right: 25px;
}
.popup-warning{
  position: absolute;
  height: max-content;
  width: max-content;
  top: 25px;
  z-index: 100;
  right: 0px;
  box-shadow:
  0px -1.7px 2.5px rgba(0, 0, 0, 0.024),
  0px -2.5px 5.5px rgba(0, 0, 0, 0.047),
  0px -2.4px 9.2px rgba(0, 0, 0, 0.07),
  0px -0.9px 13.9px rgba(0, 0, 0, 0.093),
  0px 2.4px 20px rgba(0, 0, 0, 0.116),
  0px 8.4px 28.4px rgba(0, 0, 0, 0.138),
  0px 18.5px 40.2px rgba(0, 0, 0, 0.159),
  0px 36.1px 58.4px rgba(0, 0, 0, 0.179),
  0px 70.1px 90px rgba(0, 0, 0, 0.195),
  0px 146px 160px rgba(0, 0, 0, 0.16)
;
}
